import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import { frankButtonStyle, getFrankMathildaImage, FrankMathildaLastSlideImage } from "../components/slideshows/FrankMathildaSlideshow"
import Slide from "../components/slideshows/Slide"
import LastSlide from "../components/slideshows/LastSlide"
import Nav from "../components/slideshows/Nav"
import Overview from "../components/slideshows/Overview"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

const Wrapper = styled.div`
  min-height: 100%;
  background: ${props => props.bgColor};
`

export default function FrankSlideshowTemplate({
  data: { frankSlideshowYaml, allFrankSlideshowYaml },
  pageContext: {
    lang,
    pagePath,
    basePath,
    currentSlide,
    isLast = false,
    order,
  },
}) {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "frankSlideshowTitle" })
  const slides = allFrankSlideshowYaml.nodes
  const theme = { themeColor: "#91c56e", textColor: "black", bgColor: "#91c56e" }
  const lastSlideImage = isLast ? <FrankMathildaLastSlideImage image={slides[1].imagePortrait} /> : null
  const image = isLast ? null : getFrankMathildaImage(frankSlideshowYaml)

  return (
    <Wrapper bgColor={theme.bgColor}>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={{ url: frankSlideshowYaml.imagePortrait, alt: frankSlideshowYaml.imageAltText }}
      />
      <Header
        lang={lang}
        room={3}
        color={theme.bgColor}
        isTransparent
      />
      <Overview
        lang={lang}
        basePath={basePath}
        currentSlide={currentSlide}
        slides={slides}
        bgColor={theme.bgColor}
      />
      {isLast ?
        <LastSlide
          lang={lang}
          basePath={basePath}
          image={lastSlideImage}
          buttonStyle={frankButtonStyle}
        />
      :
        <Slide
          image={image}
          sound={frankSlideshowYaml.sound}
          text={frankSlideshowYaml.text}
          slideshowTheme={theme}
        />
      }
      <Nav
        currentSlide={currentSlide}
        isLast={isLast}
        color={theme.bgColor}
        prevLink={currentSlide > 1 ? `/${lang}/${basePath}/${currentSlide - 1}` : null}
        nextLink={!isLast ? `/${lang}/${basePath}/${currentSlide + 1}` : null}
      />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $order: Float) {
    frankSlideshowYaml(lang: { eq: $lang }, order: { eq: $order }) {
      text
      sound
      imagePortrait
      imageAltText
    }
    allFrankSlideshowYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        order
        imagePortrait
      }
    }
  }
`
