import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const buttonStyle = `
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 9px 16px 9px;
  background: none;
  border-radius: 9999px;
  color: #fff;
  font-size: .9rem;
  font-weight: 600;

  &:hover,
  &:focus {
    color: white;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-right: -2px;
    width: auto;
    height: .9rem;
  }
`

export const frankButtonStyle = `
  ${buttonStyle}
  background: linear-gradient(0deg, #7AA55D 0%, #C2DCA3 100%);

  &:active {
    background: linear-gradient(0deg, #C2DCA3 0%, #7AA55D 100%);
  }
`

export const mathildaButtonStyle = `
  ${buttonStyle}
  background: linear-gradient(0deg, #836D96 0%, #E2D9EA 100%);

  &:active {
    background: linear-gradient(0deg, #E2D9EA 0%, #836D96 100%);
  }
`

const Figure = styled.figure`
  padding: 3%;
  background: white;
`

export const getFrankMathildaImage = ({ imagePortrait, imageAltText }) => {
  const image = encodeURI(imagePortrait)

  return (
    <Figure>
      <img
        src={image}
        alt={imageAltText}
        srcSet={`${image}?nf_resize=fit&w=648 420w, ${image}?nf_resize=fit&w=572 700w, ${image}?nf_resize=fit&w=1500 1500w`}
      />
    </Figure>
  )
}

const LastSlideImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  margin: 0 auto 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: white;

  span {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  @media (min-width: 768px) and (min-height: 768px) {
    width: 14rem;
    height: 14rem;
    padding: 0.75rem;
    margin-bottom: 3.5rem;
  }

  @media (orientation: landscape) {
    margin-bottom: 0;
  }
`

export const FrankMathildaLastSlideImage = ({ image }) => {
  return (
    <LastSlideImageWrapper>
      <span style={{ backgroundImage: `url(${encodeURI(image)})` }} />
    </LastSlideImageWrapper>
  )
}
